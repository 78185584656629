import { Link, useSearchParams } from "react-router-dom";
// import ThemeChanger from "./DarkSwitch";
import { Disclosure } from "@headlessui/react";
// import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import logo from "../img/logo.svg";
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import { Button, IconButton, Popover } from "@mui/material";
import { useState } from "react";
import { useAuthInfo, useLogoutFunction } from "@propelauth/react";
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import { styled, alpha } from '@mui/material/styles';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 99,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.10),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '28ch',
            },
        },
    },
}));

const Navbar = () => {
    const authInfo = useAuthInfo();
    const logoutFunction = useLogoutFunction();
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [langAnchorEL, setLangAnchorEL] = useState(null);

    const navigation = [
        [t('about.title'), "/about"],
        // [t('Supplies Needed'), "/items"],
        // [t('Pick up points'), "#features"],
        // [t('Organizations'), "#features"],
        // [t('FAQs'), "#faqs"],
        // [t('Contact Us'), "#contact"],
        // "Pricing",
        // "Company",
        // "Blog",
    ];


    const onSearchChange = (e) => {
        if (e.target.value === '') {
            searchParams.delete('search');
            setSearchParams(searchParams);
            return;
        }
        searchParams.set('search', e.target.value);
        setSearchParams(searchParams);
    }

    return (
        <div className="w-full sticky top-0 px-8 z-10 bg-[#ffffffcc] backdrop-blur-md shadow-lg dark:shadow-[#111827cc] dark:bg-[#111827cc]">
            <nav className="container relative flex flex-wrap items-center justify-between py-4 xl:py-8 mx-auto lg:justify-between xl:px-0">
                {/* Logo  */}
                <Disclosure>
                    {({ open }) => (
                        <>
                            <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                                <Link to="/">
                                    <span className="flex items-center space-x-2 text-2xl font-bold text-[#4d4ea1] dark:text-gray-100">
                                        <span className=" text-white fill-white border-white">
                                            <img
                                                src={logo}
                                                alt="N"
                                                width="32"
                                                height="32"
                                                className={`w-10 p-1.5 bg-white rounded-md ${i18n.language === 'he' ? 'transform scale-x-[-1]' : ''}`}
                                            />
                                        </span>
                                        <span className="w-min text-start leading-none">{t('Civil Power')}</span>
                                    </span>
                                </Link>
                                <div className="flex items-center w-36 justify-center ms-auto lg:hidden">
                                    {window.location.pathname === '/' &&
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                placeholder={t("Search...")}
                                                inputProps={{ 'aria-label': 'search' }}
                                                value={searchParams.get('search') || ''}
                                                onChange={onSearchChange}
                                            />
                                        </Search>}
                                </div>
                                <Disclosure.Button
                                    aria-label="Toggle Menu"
                                    className="px-2 py-1 ms-auto text-gray-500 rounded-md lg:hidden hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:text-gray-300 dark:focus:bg-gray-700">
                                    <svg
                                        className="w-6 h-6 fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24">
                                        {open && (
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                            />
                                        )}
                                        {!open && (
                                            <path
                                                fillRule="evenodd"
                                                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                                            />
                                        )}
                                    </svg>
                                </Disclosure.Button>

                                <Disclosure.Panel className="flex flex-col w-full my-5 lg:hidden">
                                    <>
                                        <siv className="flex">
                                            <Button
                                                startIcon={<LanguageRoundedIcon />}
                                                onClick={(e) => setLangAnchorEL(e.currentTarget)}
                                            >
                                                {i18n.language === 'he' ? 'עברית' : 'English'}
                                            </Button>
                                        </siv>
                                        {navigation.map((item, index) => (
                                            <a key={index} href={item[1]} className="w-full text-start px-4 py-2 -ms-4 text-gray-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 dark:focus:bg-gray-800 focus:outline-none">
                                                {item[0]}
                                            </a>
                                        ))}
                                        {authInfo.isLoggedIn ? (
                                            <div className="flex flex-col gap-y-2 mt-2">
                                                <div className="flex">
                                                    <Link
                                                        to="/account"
                                                        className="flex items-center px-2 py-2 text-gray-700 bg-gray-200 rounded-full md:me-5"
                                                    >
                                                        <PermIdentityRoundedIcon />
                                                        <div>
                                                            {t('My Account')}
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="flex">
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        onClick={logoutFunction}
                                                        startIcon={<ExitToAppRoundedIcon />}
                                                        sx={{ borderRadius: 99 }}
                                                    >
                                                        {t('Logout')}
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <a
                                                    href={`${process.env.REACT_APP_AUTH_URL}/login`}
                                                    className="px-6 py-2 mt-3 text-center text-white bg-indigo-700 rounded-full lg:ms-5">
                                                    {t('Login')}
                                                </a>
                                            </div>
                                        )}
                                    </>
                                </Disclosure.Panel>
                            </div>
                        </>
                    )}
                </Disclosure>

                {/* menu  */}
                <div className="hidden text-center me-auto ms-4 lg:flex lg:items-center">
                    <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
                        {navigation.map((menu, index) => (
                            <li className="me-3 nav__item" key={index}>
                                <a href={menu[1]} className="inline-block px-4 py-2 text-lg font-normal text-gray-800 no-underline rounded-md dark:text-gray-200 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-gray-800">
                                    {menu[0]}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="hidden text-center me-auto ms-4 lg:flex lg:items-center">
                    {window.location.pathname === '/' &&
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder={t("Search...")}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchParams.get('search') || ''}
                                onChange={onSearchChange}
                            />
                        </Search>}
                </div>
                <div className="hidden justify-end me-3 space-x-4 lg:flex nav__item">
                    <IconButton
                        onClick={(e) => setLangAnchorEL(e.currentTarget)}
                    >
                        <LanguageRoundedIcon />
                    </IconButton>
                    {authInfo.isLoggedIn ? (
                        <div className="flex">
                            <Link
                                to="/account"
                                className="flex items-center px-4 py-2 text-gray-700 bg-gray-200 rounded-full md:me-5"
                            // onClick={logoutFunction}
                            >
                                <PermIdentityRoundedIcon fontSize="small" />
                                <div>
                                    {t('My Account')}
                                </div>
                            </Link>
                            <div className="flex">
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={logoutFunction}
                                    startIcon={<ExitToAppRoundedIcon />}
                                    sx={{ borderRadius: 99 }}
                                >
                                    {t('Logout')}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <a
                            href={`${process.env.REACT_APP_AUTH_URL}/login`}
                            className="px-6 py-2 text-white bg-indigo-700 rounded-full md:me-5"
                        >
                            {t('Login')}
                        </a>
                    )}
                </div>
            </nav>
            <Popover
                open={langAnchorEL}
                onClose={() => setLangAnchorEL(null)}
                anchorEl={langAnchorEL}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <div className="flex flex-col items-center justify-center p-4">
                    <Button
                        onClick={() => {
                            i18n.changeLanguage('he');
                            if (localStorage.getItem('lang') !== 'all') {
                                localStorage.setItem('lang', 'he')
                            }
                            setLangAnchorEL(null)
                        }}
                        sx={{ textTransform: 'none' }}
                    >
                        עברית
                    </Button>
                    <Button
                        onClick={() => {
                            i18n.changeLanguage('en');
                            if (localStorage.getItem('lang') !== 'all') {
                                localStorage.setItem('lang', 'en')
                            }
                            setLangAnchorEL(null)
                        }}
                        sx={{ textTransform: 'none' }}
                    >
                        English
                    </Button>
                </div>
            </Popover>
        </div>
    );
}

export default Navbar;