import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/tailwind.css';
import './styles/index.css';
import './index.css';
import { AuthProvider } from "@propelauth/react";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import PWAPrompt from 'react-ios-pwa-prompt';
import ReactGA from "react-ga4";

ReactGA.initialize('G-WJQ3JG1BT6')
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL}>
      <App />
    </AuthProvider>
    <PWAPrompt
      promptOnVisit={1}
      timesToShow={3}
      copyClosePrompt="Close"
      permanentlyHideOnDismiss={false}
    />
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
