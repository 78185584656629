import { Chip, IconButton, Modal } from "@mui/material"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { useAuthInfo } from "@propelauth/react";
import LoginModal from "./LoginModal";
import { useSearchParams } from "react-router-dom";
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { Helmet } from "react-helmet";
import { shareEventGA } from "../Helpers/generalHelper";

const image = 'https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697056416/vecteezy_volunteers-stylish-and-abstract-team-help-charity-and-share_5611304_osklym.jpg'

function ItemModal({ open, setOpen, categoriesObject, item, contacts }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { i18n, t } = useTranslation();

    const authInfo = useAuthInfo();

    const [loginModalOpen, setLoginModalOpen] = useState(false);

    const share = () => {
        if (navigator.share) {
            navigator.share({
                title: item.item,
                text: item.description,
                url: window.location.href,
            })
                .then(() => shareEventGA('Share Success'))
                .catch(() => shareEventGA('Share Failed'));
        }
    }

    return (
        <Modal
            open={open}
            onClose={setOpen}
        >
            <>
                {open && item &&
                    <div className="bg-white absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl p-2" >
                        <div className="flex justify-end">
                            <IconButton
                                onClick={setOpen}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        </div>
                        <div className="overflow-y-scroll overflow-x-hidden max-h-[80vh]">
                            <div className="w-[85vw] md:w-[26rem] lg:w-[28rem] max-w-[28rem] text-start bg-white border rounded-2xl px-4 pt-2 pb-4 border-gray-50 drop-shadow-sm">
                                <div className="relative">
                                    <div className="absolute bottom-0 m-2" >
                                        <IconButton
                                            sx={{
                                                backgroundColor: '#ffffff7f',
                                                '&:hover': {
                                                    backgroundColor: '#ffffffc7'
                                                }
                                            }}
                                            onClick={share}
                                        >
                                            <ShareRoundedIcon />
                                        </IconButton>
                                    </div>
                                    <img src={item.image_url || image} alt="" className='object-cover rounded-lg w-full h-40 sm:h-60 lg:h-[11.2rem]' />
                                </div>
                                <div className="m-2">
                                    <Chip
                                        label={categoriesObject?.[item?.category]?.[i18n.language === 'en' ? 'category' : 'category_he']}
                                        size=""
                                        variant="outlined"
                                        className="mb-2 float-right rtl:float-left align-middle mt-0.5"
                                        sx={{ borderColor: categoriesObject[item?.category]?.color, color: categoriesObject[item?.category]?.color }}
                                    />
                                    <h2 className="text-xl font-bold my-2">{item.item}</h2>
                                    <p className="">{item.description}</p>
                                    <div className="flex items-center mt-4">
                                        {item.location && <LocationOnOutlinedIcon className="text-gray-700 -ms-1" fontSize="small" />}
                                        <p className="text-xs text-gray-700 font-bold ">{item.location}</p>
                                    </div>
                                    {item?.contacts?.length ?
                                        <div>
                                            {authInfo.isLoggedIn ? (
                                                <div className="mt-2">
                                                    {contacts?.map((contact, index) => {
                                                        return (
                                                            <div className="text-xs text-gray-700 md:flex gap-4 items-center font-bold mt-4" key={index}>
                                                                {contact.name && <div className=" items-center"><PersonRoundedIcon fontSize="small" className="me-1" sx={{ fontSize: '1rem' }} />
                                                                    {contact.name}
                                                                </div>}
                                                                <div>
                                                                    {contact.phone && <a className="text-blue-800" href={`tel:${contact.phone}`}>
                                                                        <LocalPhoneRoundedIcon fontSize="small" className="me-1" sx={{ fontSize: '1rem' }} />{contact.phone}
                                                                    </a>}
                                                                </div>
                                                                <div>
                                                                    {contact.email && <a className="text-blue-800" href={`mailto:${contact.email}`}>
                                                                        <EmailRoundedIcon fontSize="small" className="me-1" sx={{ fontSize: '1rem' }} />{contact.email}
                                                                    </a>}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            ) : (
                                                <div className="mt-2">
                                                    {item.contacts.map((contact, index) => {
                                                        return (
                                                            <div className="text-gray-700 flex gap-4 items-center mt-4" key={index}>
                                                                {contact.name && <div className="text-xs text-gray-700 font-bold "><PersonRoundedIcon fontSize="small" className="me-1" sx={{ fontSize: '1rem' }} />
                                                                    {contact.name}
                                                                </div>}
                                                                {contact.phone_exists &&
                                                                    <div className="items-center text-xs font-bold text-blue-900 cursor-pointer"
                                                                        onClick={() => setLoginModalOpen(true)}
                                                                    >
                                                                        <LocalPhoneRoundedIcon className="me-1" sx={{ fontSize: '1rem' }} />
                                                                        {t('Phone Number')}
                                                                    </div>
                                                                }
                                                                {contact.email_exists &&
                                                                    <div
                                                                        className="items-center text-xs font-bold text-blue-900 cursor-pointer"
                                                                        onClick={() => setLoginModalOpen(true)}
                                                                    >
                                                                        <EmailRoundedIcon fontSize="small" className="me-1" sx={{ fontSize: '1rem' }} />
                                                                        {t('Email')}
                                                                    </div>}
                                                            </div>
                                                        )
                                                    })}
                                                </div>)}
                                        </div> : null}
                                    {item.link &&
                                        <a className="flex gap-1 mt-4" href={item.link}>
                                            <LanguageRoundedIcon className="text-blue-800 -ms-1" fontSize="small" />
                                            <p className="text-xs text-blue-800 font-bold ">{item.link}</p>
                                        </a>}
                                </div>
                            </div>
                        </div>
                        <LoginModal
                            open={loginModalOpen}
                            setOpen={setLoginModalOpen}
                            title={t('Login')}
                            description={t('Login to see the contact details')}
                        />
                    </div>
                }
            </>
        </Modal>
    )
}

export default ItemModal