import axios from "axios";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Button, CircularProgress, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useAuthInfo } from "@propelauth/react";
import { transformFieldsToServerFormat } from '../Helpers/generalHelper';
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import useSnackbar from '../Helpers/SnackbarUtil';

function NewItem({ open, setOpen, categories, onUpdate }) {
    const { t, i18n } = useTranslation();
    const token = useAuthInfo().accessToken;
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState('');
    const fileInputRef = useRef(null);

    const [saving, setSaving] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    const [item, setItem] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [link, setLink] = useState('');

    const [errors, setErrors] = useState({
        item: true,
        category: true,
    });

    const [fields, setFields] = useState([
        { name: '', phone: '', email: '' }
    ]);

    const { showMessage, RenderSnackbar } = useSnackbar();

    useEffect(() => {
        if (!open) {
            setImage(null);
            setImageName('');
            setItem('');
            setDescription('');
            setCategory('');
            setLocation('');
            setLink('');
            setErrors({
                item: true,
                category: true,
            });
            setFields([
                { name: '', phone: '', email: '' }
            ]);
        }
    }, [open]);

    const handleInputChange = (index, field, value) => {
        const newFields = [...fields];
        newFields[index][field] = value;
        setFields(newFields);
    };

    useEffect(() => {
        console.log(fields);
    }, [fields]);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    };

    const handleFiles = (files) => {
        if (files[0].type === "image/svg+xml") {
            showMessage('error', t('SVG images are not allowed.'));
            // Clear the input
        } else if (files[0].type.includes("image")) {
            setUploadingImage(true)
            const formData = new FormData();
            formData.append('image', files[0]);
            axios.post('/upload-image/', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((res) => {
                console.log(res.data);
                setImage(res.data.image_url);
                setUploadingImage(false);
            }).catch((err) => {
                console.log(err);
                setUploadingImage(false);
            })
        } else {
            console.log("File is not an image.");
            // Handle the error appropriately
        }

    };

    const handleRemoveFields = (indexToRemove) => {
        const newFields = fields.filter((_, index) => index !== indexToRemove);
        setFields(newFields);
    };

    const removeImage = (e) => {
        e.stopPropagation();
        setImage(null);
        setImageName('');
        fileInputRef.current.value = ""; // Reset the input value
    };

    const handleSave = () => {
        setSaving(true);
        let data = {
            item,
            description,
            category,
            location,
            link,
            image_url: image,
            contacts: fields,
            item_type: 'requests',
        };

        axios.post('/item/create-new/', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((res) => {
            showMessage('success', t('Item created successfully!'))
            setOpen(false);
            setSaving(false);
            searchParams.set('item', res.data.id);
            setSearchParams(searchParams);
        }).catch((err) => {
            showMessage('error', err);
            setSaving(false);
        })
    }


    return (
        <>
            <Modal
                open={open}
                onClose={() => { setOpen(false) }}
            >
                <div className="bg-white w-full md:max-w-2xl h-full md:max-h-[90vh] absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-none md:rounded-3xl p-4" >
                    <div className="flex justify-end">
                        <IconButton
                            onClick={() => { setOpen(false) }}
                        >
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <div className="px-8 pb-16 md:8 overflow-y-scroll overflow-x-hidden h-full md:h-[80vh]">
                        <div className="flex flex-col gap-y-8">
                            <h2 className="text-2xl font-bold mb-4">{t('New Request')}</h2>
                            <div className="w-1/2">
                                <TextField
                                    required
                                    error={errors.item}
                                    helperText={errors.item ? t('requiredError') : ''}
                                    fullWidth
                                    label={t('Item Needed')}
                                    variant="standard"
                                    value={item}
                                    onChange={(e) => {
                                        setItem(e.target.value);
                                        setErrors({
                                            ...errors,
                                            item: !e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    label={t('Description')}
                                    variant="standard"
                                    multiline
                                    rows={2}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                            <div
                                className="upload-container p-4 border-dashed border-2 cursor-pointer hover:bg-gray-100 transition-colors"
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onClick={() => fileInputRef.current.click()}
                                elevation={3}
                            >
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    // accept="image/*"
                                    accept="image/jpeg,image/png"
                                    className="hidden"
                                    onChange={(e) => handleFiles(e.target.files)}
                                />
                                {image ? (
                                    <div className="relative text-start w-fit justify-center mx-auto">
                                        <IconButton
                                            size="small"
                                            sx={{ backgroundColor: 'white', border: '1px solid #E5E7EB', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                                            className="absolute top-6 float-right right-3"
                                            onClick={removeImage}
                                        >
                                            <ClearRoundedIcon />
                                        </IconButton>
                                        <img
                                            src={image}
                                            alt="Selected Preview"
                                            className="w-full h-32 object-contain rounded"
                                        />
                                    </div>
                                ) : (
                                    uploadingImage ? (
                                        <div className="justify-center items-center">
                                            <CircularProgress />
                                            <p className="text-sm font-semibold text-gray-500 text-center">{t('Uploading')}...</p>
                                        </div>
                                    ) : (
                                        <>
                                            <Typography variant="body1" className="hidden md:block">
                                                {t('Drag & drop an image here, or')}<br /><br />
                                            </Typography>
                                            <Button variant="outlined" color="primary" className="mt-2">
                                                {t('Choose an Image')}
                                            </Button>
                                        </>

                                    ))}
                                {!image && !uploadingImage && <p className="text-sm font-semibold text-gray-500 text-center">{t('imageNote')}</p>}
                            </div>
                            <div className="flex gap-x-8">
                                <FormControl className="w-1/2 text-start" hel variant="standard">
                                    <InputLabel required id="category-select-label">{t('Category')}</InputLabel>
                                    <Select
                                        required
                                        error={errors.category}
                                        labelId="category-select-label"
                                        label={t('Category')}
                                        value={category}
                                        onChange={(e) => {
                                            setCategory(e.target.value)
                                            console.log(e.target.value);
                                            setErrors({
                                                ...errors,
                                                category: !e.target.value
                                            })
                                        }}
                                    >
                                        {categories.map((category, index) => {
                                            return (
                                                <MenuItem key={index} value={category.id}>
                                                    {i18n.language === 'en' && category.category}
                                                    {i18n.language === 'he' && category.category_he}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {errors.category && <FormHelperText>{t('requiredError')}</FormHelperText>}
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label={t('Location')}
                                    variant="standard"
                                    value={location}
                                    helperText={<span className="text-red-500 font-bold">{t('locationCautionNote')}</span>}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                            <div>
                                {fields.map((fieldData, index) => (
                                    <div key={index} className="sm:flex gap-x-8">
                                        <TextField
                                            fullWidth
                                            label={t('Contact Name')}
                                            variant="standard"
                                            value={fieldData.name}
                                            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                        />
                                        <TextField
                                            fullWidth
                                            label={t('Phone Number')}
                                            variant="standard"
                                            value={fieldData.phone}
                                            onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                                        />
                                        <TextField
                                            fullWidth
                                            label={t('Email')}
                                            variant="standard"
                                            value={fieldData.email}
                                            onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                        />
                                        {index > 0 && (
                                            <IconButton onClick={() => handleRemoveFields(index)}>
                                                <CloseRoundedIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                ))}
                                <div className="text-xs text-start font-semibold my-2">
                                    {t('Notice! the contact details will be visible to all logged in users')}
                                </div>
                                <Button onClick={() => setFields([...fields, { name: '', phone: '', email: '' }])}>
                                    {t('addContactButtonText')}
                                </Button>
                            </div>
                            <div className="flex gap-x-8">
                                <TextField
                                    fullWidth
                                    label={t('Link')}
                                    variant="standard"
                                    value={link}
                                    helperText={t('linkNote')}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                            </div>
                            <div className="flex justify-center">
                                <LoadingButton
                                    variant="contained"
                                    disabled={errors.item || errors.category || uploadingImage}
                                    color="primary"
                                    sx={{ borderRadius: 99 }}
                                    loading={saving}
                                    onClick={handleSave}
                                >
                                    {t('Add')}
                                </LoadingButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <RenderSnackbar />
        </>
    );
}

export default NewItem;