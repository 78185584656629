import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Chip, CircularProgress, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import PageHeader from "./PageHeader";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddIcon from '@mui/icons-material/Add';
import { Box } from "@mui/system";
import NewItem from "./NewItem";
import useSnackbar from '../Helpers/SnackbarUtil';
import { shareEventGA } from "../Helpers/generalHelper";

const image = 'https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697056416/vecteezy_volunteers-stylish-and-abstract-team-help-charity-and-share_5611304_osklym.jpg'

function Account({ categories }) {
    const authInfo = useAuthInfo();
    const token = useAuthInfo().accessToken;
    const authLoading = authInfo.loading;
    const dir = document.body.dir;
    const { redirectToSignupPage, redirectToLoginPage } = useRedirectFunctions();
    const { t, i18n } = useTranslation();

    const [newProduct, setNewProduct] = useState(false);
    const [loginModalOpen, setLoginModalOpen] = useState(false);

    const [optionsAnchorEL, setOptionsAnchorEL] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [items, setItems] = useState([]);
    const [update, setUpdate] = useState(false);

    const [loading, setLoading] = useState(true);
    const { showMessage, RenderSnackbar } = useSnackbar();

    const categoriesObject = categories.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
    }, {});

    useEffect(() => {
        axios.get('/user-items/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            setItems(res.data);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }, [update, authLoading])

    const handleDelete = (id) => {
        axios.post(`/item/delete/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            showMessage('success', t('Item deleted successfully'));
            setUpdate(!update);
            setSelectedItem(null);
            setOptionsAnchorEL(null);
        }).catch((err) => {
            showMessage('error', err);
        })
    }

    const handleItemAction = (id, action) => {
        axios.post(`/item/${action}/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            showMessage('success', t('Request Uploaded Successfully'))
            setUpdate(!update);
            setSelectedItem(null);
            setOptionsAnchorEL(null);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div className="p-10">
            <div className="container mx-auto text-start">
                <PageHeader title={t('My Account')} />
                {!authInfo.isLoggedIn ? (
                    <div className="flex justify-center items-center relative top-40">
                        <div className="text-center px-4 pb-4 w-fit">
                            <h1 className="text-2xl font-bold mb-2">{t('You are not logged in')}</h1>
                            <p className="text-gray-500 mb-4">{t('Login to vyew and edit your posts')}</p>
                            {/* <a
                                href={`${process.env.REACT_APP_AUTH_URL}/login`}
                                className="px-6 py-2 mt-3 text-center text-white bg-indigo-700 rounded-full lg:ms-5">
                                {t('Login')}
                            </a> */}
                            <div className="flex justify-center mt-3 gap-x-4">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => redirectToLoginPage({
                                        postLoginRedirectUrl: window.location.href
                                    })}
                                    sx={{ borderRadius: 99 }}
                                >
                                    {t('Login')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => redirectToSignupPage({
                                        postSignupRedirectUrl: window.location.href
                                    })}
                                    sx={{ borderRadius: 99 }}
                                >
                                    {t('Sign Up')}
                                </Button>
                            </div>
                        </div>
                    </div>) : (
                    <div className="flex flex-wrap justify-center gap-x-8 gap-y-16">
                        {loading &&
                            <div className="flex justify-center items-center relative top-40">
                                <Box>
                                    <CircularProgress />
                                </Box>
                            </div>}

                        {items.length === 0 && !loading &&
                            <div className="flex justify-center items-center relative top-40">
                                <div className="text-center px-4 pb-4 w-fit">
                                    <h1 className="text-2xl font-bold mb-2">{t("You haven't uploaded requests yet")}</h1>
                                    <p className="text-gray-500 mb-4">{t('Add a request for help')}</p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            if (authInfo.isLoggedIn) {
                                                setNewProduct(true);
                                            } else {
                                                setLoginModalOpen(true)
                                            }
                                        }}
                                        sx={{ borderRadius: 99 }}
                                    >
                                        {t('Add Request')}
                                    </Button>
                                </div>
                            </div>}
                        {items.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="relative w-80 text-start bg-white border rounded-2xl p-4 border-gray-50 drop-shadow-sm"
                                // onClick={() => handleItemClick(item.id)}
                                >
                                    <div className="relative">
                                        <div className="absolute bottom-0 m-2" >
                                            <IconButton
                                                sx={{
                                                    backgroundColor: '#ffffff7f',
                                                    '&:hover': {
                                                        backgroundColor: '#ffffffc7'
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    if (navigator.share) {
                                                        navigator.share({
                                                            title: item.item,
                                                            text: item.description,
                                                            url: `${window.location.origin}?item=${item.id}`,
                                                        })
                                                            .then(() => shareEventGA('Share Success'))
                                                            .catch(() => shareEventGA('Share Failed'));
                                                    }
                                                }}
                                            >
                                                <ShareRoundedIcon />
                                            </IconButton>
                                        </div>
                                        <img src={item.image_url || image} alt="hero" className='object-cover rounded-lg w-full h-32' />
                                    </div>
                                    <div className="m-2">
                                        <Chip
                                            label={categoriesObject[item?.category]?.['category' + (i18n.language === 'he' ? '_he' : '')]}
                                            size="small"
                                            variant="outlined"
                                            className="mb-2 float-right rtl:float-left align-middle mt-0.5"
                                            sx={{ borderColor: categoriesObject[item.category]?.color, color: categoriesObject[item.category]?.color }}
                                        />
                                        <h2 className="text-xl font-bold my-2">{item.item}</h2>
                                        <p className="truncate-2-lines">{item.description}</p>
                                        <div className="align-bottom">
                                            <div className="float-right rtl:float-left relative -top-3 -end-3">
                                                {!item?.active && <AcUnitRoundedIcon color="primary" />}
                                                {item?.completed && <CheckCircleRoundedIcon color="success" />}
                                                <IconButton
                                                    onClick={(e) => {
                                                        setOptionsAnchorEL(e.currentTarget)
                                                        setSelectedItem(item)
                                                    }}
                                                // sx={{ backgroundColor: '#00000033' }}
                                                >
                                                    <MoreVertRoundedIcon />
                                                </IconButton>
                                            </div>
                                            <div className="flex items-center mt-4">
                                                {item.location && <LocationOnOutlinedIcon className="text-gray-700 -ms-1" fontSize="small" />}
                                                <p className="text-xs text-gray-700 font-bold ">{item.location}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
                <Popover
                    open={optionsAnchorEL}
                    anchorEl={optionsAnchorEL}
                    onClose={() => setOptionsAnchorEL(null)}
                    sx={{ '& .MuiPopover-paper': { borderRadius: '0.5rem' } }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: dir === 'rtl' ? 'left' : 'right',
                    }}
                >
                    <List sx={{ maxWidth: 270, bgcolor: 'background.paper' }}>
                        <ListItem disablePadding >
                            <ListItemButton onClick={() => handleDelete(selectedItem.id)}>
                                <ListItemIcon>
                                    <DeleteOutlineRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('Delete Request')} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding >
                            <ListItemButton onClick={() => handleItemAction(selectedItem?.id, selectedItem?.active ? 'freeze' : 'unfreeze')}>
                                <ListItemIcon>
                                    <AcUnitRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t(selectedItem?.active ? 'Freeze Request' : 'Unfreeze Request')} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding >
                            <ListItemButton onClick={() => handleItemAction(selectedItem?.id, selectedItem?.completed ? 'uncomplete' : 'complete')}>
                                <ListItemIcon>
                                    <CheckCircleRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary={t(selectedItem?.completed ? 'Mark as Incomplete' : 'Mark as Completed')} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Popover>
            </div>
            <NewItem open={newProduct} setOpen={setNewProduct} categories={categories} onUpdate={() => setUpdate(!update)} />
            <div className='fixed bottom-10 end-10'>
                <Fab color='primary' onClick={() => {
                    if (authInfo.isLoggedIn) {
                        setNewProduct(true);
                    } else {
                        setLoginModalOpen(true)
                    }
                }}
                >
                    <AddIcon />
                </Fab>
            </div>

            <RenderSnackbar />
        </div>
    );
}

export default Account;