import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// const cacheRtl = createCache({
//     key: 'muirtl',
//     stylisPlugins: [prefixer, rtlPlugin],
// });

// const cacheLtr = createCache({
//     key: 'muiltr',
//     stylisPlugins: [prefixer],
// });


function LanguageSwitcher(setCache) {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (i18n.language === 'he') {
            document.body.dir = 'rtl';
            localStorage.setItem('lang', 'he')
            // setCache(cacheRtl)
        } else {
            document.body.dir = 'ltr';
            localStorage.setItem('lang', 'en')
            // setCache(cacheLtr)
        }
    }, [i18n.language]);


    return (
        <div>
            <button onClick={() => i18n.changeLanguage('en')}>English</button>
            <button onClick={() => i18n.changeLanguage('he')}>Hebrew</button>
        </div>
    );
}

export default LanguageSwitcher;