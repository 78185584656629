import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
    const { t } = useTranslation();

    return (
        <footer class="bg-gray-800 text-white py-2">
            <div class="container mx-auto text-center">
                <Link to="/terms-and-conditions" class="text-xs hover:underline">
                    {t('Terms and Conditions')}
                </Link>
            </div>
        </footer>
    );
}

export default Footer;