import logo from './logo.svg';
import './App.css';
import { Chip, ThemeProvider } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import './i18n';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './Components/LangSwitch';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import theme from './theme';
import { useEffect, useState } from 'react';
import Navbar from './Components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Products from './Components/Products';
import About from './Components/About';
import axios from 'axios';
import Account from './Components/Account';
import { Helmet } from 'react-helmet';
import Footer from './Components/Footer';
import TermsAndConditions from './Components/Terms';
import { pageview } from 'react-ga4';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
});

const setLanguagePreference = (lang) => {
  if (lang.startsWith('en')) {
    localStorage.setItem('lang', 'en');
  } else if (lang === 'he') {
    localStorage.setItem('lang', 'he');
  } else {
    // Handle other languages if necessary
    localStorage.setItem('lang', 'en'); // default to English
  }
}

function App() {
  const { t } = useTranslation();
  const [cache, setCache] = useState(cacheLtr);

  const { i18n } = useTranslation();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (i18n.language === 'he') {
      document.body.dir = 'rtl';
      localStorage.setItem('i18nextLng', 'he');
      setCache(cacheRtl)
    } else {
      setCache(cacheLtr)
      localStorage.setItem('i18nextLng', 'en');
      document.body.dir = 'ltr';
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!localStorage.getItem('lang') || ['en', 'he', 'all'].indexOf(localStorage.getItem('lang')) === -1) {
      setLanguagePreference(i18n.language);
    }
    axios.get('/categories/').then(res => {
      setCategories(res.data);
    }).catch(err => {
      console.log(err);
    })
  }, [])

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <html lang={i18n.language} />
          <title>{t('Civil Power')}</title>
          <meta name="description" content={t('myDescription')} />
        </Helmet>
        <div className="App bg-[#F5F6FA] min-h-screen flex flex-col">
          <BrowserRouter >
            <div className='flex-1'>
              <Navbar />
              <Routes>
                <Route path='/' element={<Products categories={categories} />} />
                <Route path='/account' element={<Account categories={categories} />} />
                <Route path='/about' element={<About />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
              </Routes>
            </div>
            <Footer />
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;