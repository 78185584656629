import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    // detection: {
    //   // Set the order to prioritize localStorage
    //   order: ['localStorage', 'navigator', 'htmlTag'],

    //   // Specify the key for localStorage
    //   lookupLocalStorage: 'i18nextLng',

    //   // Specify where the detected language will be cached
    //   caches: ['localStorage'],
    // }
  });

export default i18n;
