import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    direction: 'rtl',
    palette: {
        primary: {
            main: '#1e3a8a',
        },
        secondary: {
            main: '#1e3b8a44',
        }
    },
});

export default theme;