import { Checkbox, Chip, Fab, FormControl, IconButton, InputLabel, LinearProgress, ListItemText, MenuItem, OutlinedInput, Select, setRef } from "@mui/material";
import PageHeader from "./PageHeader";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState, useRef } from "react";
import NewItem from "./NewItem";
import axios from "axios";
import { useAuthInfo } from "@propelauth/react";
import ItemModal from "./ItmeModal";
import { InView } from 'react-intersection-observer';
import CircularProgress from '@mui/material/CircularProgress';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import Box from '@mui/material/Box';
import LoginModal from "./LoginModal";
import { Helmet } from "react-helmet";
import { debounce } from "lodash";
import { shareEventGA } from "../Helpers/generalHelper";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const languages = [
    {
        value: 'he',
        label: 'Hebrew Posts Only',
    },
    {
        value: 'en',
        label: 'English Posts Only',
    },
    {
        value: 'all',
        label: 'All Languages',
    }
]

const image = 'https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697056416/vecteezy_volunteers-stylish-and-abstract-team-help-charity-and-share_5611304_osklym.jpg'

function Products({ categories }) {
    const { t, i18n } = useTranslation();
    const authInfo = useAuthInfo();
    const token = authInfo.accessToken;
    const authLoading = authInfo.loading;
    const loggedIn = authInfo.isLoggedIn;

    const [Update, setUpdate] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loginModalOpen, setLoginModalOpen] = useState(false);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filteredCategories, setFilteredCategories] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const fetchedPageRef = useRef();

    const categorySP = searchParams.get('categories')?.split(',') || [];

    const handleCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        let newCategories = typeof value === 'string' ? value?.split(',') : value
        if (newCategories.length === 0) {
            searchParams.delete('categories');
        } else {
            searchParams.set('categories', newCategories);
        }
        setSearchParams(searchParams);
        setPage(1);
        fetchedPageRef.current = null;
        setUpdate(!Update);
    };


    const fetchProducts = () => {
        if (fetchedPageRef.current === page) return; // Don't fetch if we've already fetched this page

        let params = {
            page,
        }

        if (searchParams.get('categories')) {
            params.categories = searchParams.get('categories')
        }
        if (localStorage.getItem('lang') !== 'all' && localStorage.getItem('lang')) {
            params.language = localStorage.getItem('lang')
        }
        if (searchParams.get('search')) {
            params.search = searchParams.get('search')
        }


        setLoading(true);
        // console.log(`Fetching page ${page}`);

        if (count === productslist.length) {
            setHasMore(false);
            setLoading(false);
        } else {
            axios.get(`/items/requests`, { params }).then((res) => {
                setCount(res.data.count);
                if (page === 1) {
                    setProductslist(res.data.results);
                } else {
                    setProductslist(prev => [...prev, ...res.data.results]);
                }
                setLoading(false);
                setRefresh(false);
            });
        }

        fetchedPageRef.current = page; // Update the ref with the page we just fetched
    };


    useEffect(() => {
        fetchProducts();
    }, [page, Update]);

    useEffect(() => {
        setPage(1);
        setCount(null);
        setHasMore(true);
        fetchedPageRef.current = null;
        setUpdate(!Update);
    }, [searchParams.get('categories')])

    const debouncedSearchEffect = debounce(() => {
        setPage(1);
        setCount(null);
        setHasMore(true);
        fetchedPageRef.current = null;
        setUpdate(!Update);
    }, 500);

    useEffect(() => {
        debouncedSearchEffect();
        
        return () =>  debouncedSearchEffect.cancel();
    }, [searchParams.get('search')])

    const [openedIem, setOpenedItem] = useState();
    const [contacts, setContacts] = useState([]);

    const open = Boolean(searchParams.get('item')) || false;

    useEffect(() => {
        setOpenedItem(null);
        setContacts([]);
        if (!open || loading) return;
        axios.get('/items/requests', {
            params: {
                id: searchParams.get('item')
            }
        }).then((res) => {
            setOpenedItem(res.data.results[0]);
        }).catch((err) => {
            console.log(err);
        })
    }, [open, authLoading])

    useEffect(() => {
        if (!open || loading || !loggedIn) return;
        axios.get('item/contact/', {
            params: {
                id: searchParams.get('item')
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            setContacts(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [open, loggedIn, loading])


    const categoriesObject = categories.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
    }, {});

    const [productslist, setProductslist] = useState([]);

    const [newProduct, setNewProduct] = useState(false);

    const handleItemClick = (id) => {
        searchParams.set('item', id);
        setSearchParams(searchParams);
    }

    return (
        <div className="p-10">
            <div className="container mx-auto text-start">
                {openedIem &&
                    <Helmet>
                        <meta property="image" content={openedIem?.image || image} />
                        <meta property="description" content={openedIem?.description} />
                    </Helmet>}
                <div className="flex flex-col">
                    <div>
                        <div className='sm:flex sm:justify-between'>
                            <div>
                                <PageHeader title={t('Requests')} />
                            </div>
                            <div className="flex justify-center">
                                <FormControl sx={{ m: 1, width: 200 }} size="small">
                                    {/* <InputLabel id="selected-categories-label">Tag</InputLabel> */}
                                    <Select
                                        // labelId="selected-categories-label"
                                        id="selected-categories"
                                        multiple
                                        value={categorySP}
                                        onChange={handleCategoryChange}
                                        // label="Tag"
                                        displayEmpty  // this prop is crucial for displaying placeholder
                                        renderValue={(selected) =>
                                            selected.length > 0
                                                ? selected.map((value) => categoriesObject[value]?.['category' + (i18n.language === 'he' ? '_he' : '')]).join(', ')
                                                : t('All Categories')  // This is your placeholder text
                                        }
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem disabled value="">
                                            <em>{t('Select a category')}...</em>
                                        </MenuItem>
                                        {categories.map((category) => (
                                            <MenuItem key={category.id} value={category.id.toString()}>
                                                <Checkbox checked={categorySP.includes(category.id.toString())} />
                                                <ListItemText primary={categoriesObject[category.id]?.['category' + (i18n.language === 'he' ? '_he' : '')]} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 200 }} size="small">
                                    {/* <InputLabel id="selected-categories-label">Tag</InputLabel> */}
                                    <Select
                                        // labelId="selected-categories-label"
                                        id="selected-categories"
                                        value={localStorage.getItem('lang') || 'all'}
                                        onChange={(e) => {
                                            localStorage.setItem('lang', e.target.value);
                                            setPage(1);
                                            fetchedPageRef.current = null;
                                            setCount(null);
                                            setHasMore(true);
                                            fetchedPageRef.current = null;
                                            setUpdate(!Update);
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem disabled value="">
                                            <em>{t('Select a language')}...</em>
                                        </MenuItem>
                                        {languages.map((lang) => (
                                            <MenuItem key={lang.value} value={lang.value}>
                                                {t(lang.label)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {/* <PageHeader title={t('Requests')} /> */}
                    </div>
                    <div className="flex flex-wrap justify-center gap-x-8 gap-y-16">
                        {productslist.map((product, index) => {
                            return (
                                <div
                                    key={index}
                                    className="w-80 text-start bg-white cursor-pointer border rounded-2xl p-4 border-gray-50 drop-shadow-sm"
                                    onClick={() => handleItemClick(product.id)}
                                >
                                    <div className="relative">
                                        <div className="absolute bottom-0 m-2" >
                                            <IconButton
                                                sx={{
                                                    backgroundColor: '#ffffff7f',
                                                    '&:hover': {
                                                        backgroundColor: '#ffffffc7'
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    if (navigator.share) {
                                                        navigator.share({
                                                            title: product.item,
                                                            text: product.description,
                                                            url: `${window.location.origin}?item=${product.id}`,
                                                        })
                                                        .then(() => shareEventGA('Share Success'))
                                                        .catch(() => shareEventGA('Share Failed'));
                                                    }
                                                }}
                                            >
                                                <ShareRoundedIcon />
                                            </IconButton>
                                        </div>
                                        <img src={product.image_url || image} alt="hero" className='object-cover rounded-lg w-full h-32' />
                                    </div>
                                    <div className="m-2">
                                        <Chip
                                            label={categoriesObject[product?.category]?.['category' + (i18n.language === 'he' ? '_he' : '')]}
                                            size="small"
                                            variant="outlined"
                                            className="mb-2 float-right rtl:float-left align-middle mt-0.5"
                                            sx={{ borderColor: categoriesObject[product.category]?.color, color: categoriesObject[product.category]?.color }}
                                        />
                                        <h2 className="text-xl font-bold my-2">{product.item}</h2>
                                        <p className="truncate-2-lines">{product.description}</p>
                                        <div className="flex items-center mt-2">
                                            {product.location && <LocationOnOutlinedIcon className="text-gray-700 -ms-1" fontSize="small" />}
                                            <p className="text-xs text-gray-700 font-bold ">{product.location}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {loading &&
                    <div className="flex justify-center items-center">
                        {page === 1 ?
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                            :
                            <Box sx={{ width: '100%', mt: '2rem', borderRadius: 99 }}>
                                <LinearProgress />
                            </Box>}
                    </div>}
                {!loading && productslist.length === 0 &&
                    <div className="flex justify-center items-center relative top-40">
                        <div className="text-center px-4 pb-4 w-fit">
                            <h1 className="text-2xl font-bold mb-2">{t('No results')}</h1>
                            <p className="text-gray-500">{t('Try changing your filters')}</p>
                        </div>
                    </div>}
                <InView as="div" onChange={(inView) => {
                    // console.log('InView triggered:', inView);
                    if (inView && !loading && productslist.length && hasMore) {
                        setPage(prev => prev + 1);
                    }
                }}>
                    <div style={{ height: '1px' }}></div>
                </InView>
            </div>
            {/* temp */}
            <div className='fixed bottom-10 end-10'>
                {/* <Fab color='primary' component={Link} to='/donations/campaigns/new'> */}
                <Fab color='primary' onClick={() => {
                    if (authInfo.isLoggedIn) {
                        setNewProduct(true);
                    } else {
                        setLoginModalOpen(true)
                    }
                }}
                >
                    <AddIcon />
                </Fab>
            </div>
            <NewItem open={newProduct} setOpen={setNewProduct} categories={categories} onUpdate={() => {
                setPage(1);
                setProductslist([]);
            }} />
            <ItemModal open={Boolean(searchParams.get('item'))} item={openedIem} contacts={contacts} setOpen={() => {
                searchParams.delete('item');
                setSearchParams(searchParams);
            }}
                categoriesObject={categoriesObject}
            />
            <LoginModal open={loginModalOpen} setOpen={setLoginModalOpen} title={t('Login to continue')} description={t('Please login to post a request')} />
        </div>
    )
}

export default Products;
