import ReactGA from "react-ga4";

export function transformFieldsToServerFormat(fields) {
    const result = {};

    fields.forEach((field, index) => {
        result[`contacts-${index}-name`] = field.contactName;
        result[`contacts-${index}-phone`] = field.phoneNumber;
        result[`contacts-${index}-email`] = field.email;
    });

    return result;
}

export function shareEventGA(action) {
    ReactGA.event({
        category: "Share",
        action: action,
    });
}