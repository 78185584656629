import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";

const a = 'https://res.cloudinary.com/hr1dzpvjo/image/upload/v1/media/images/children-smiling-2_gvjvur'

const data = [
    {
        "name": "Shmuel Trevor",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697383407/media/images/acvavpw9j1vw1uopi30x.jpg",
        "link_name": " - LinkedIn",
        "link": "https://www.linkedin.com/in/shmuel-trevor/"
    },
    {
        "name": "Netanel Hazan",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697382874/media/images/pjw6gkae1tubidrw2qts.jpg",
        "link_name": " - X",
        "link": "https://www.linkedin.com/in/netanel-hazan/"
    },
    {
        "name": "Meira Eckman",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697383214/media/images/gk2hbju4hf9jnhr6jde2.png",
        "link_name": " - LinkedIn",
        "link": "https://www.linkedin.com/in/meira-eckman-2b7597208/overlay/photo/"
    },
    {
        "name": "Emily Zwiebel",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697383113/media/images/kuman2wqyjeglsoqqyme.png",
        "link_name": "",
        "link": ""
    },
    {
        "name": "Ariel Hillel",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697382803/media/images/u0opad8exnydhrq4j7if.jpg",
        "link_name": " - LinkedIn",
        "link": "https://www.linkedin.com/in/arielhillel/"
    },
    {
        "name": "Eliyahu Goldberg",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697383338/media/images/gxwujrfer0hlvmorjvmb.jpg",
        "link_name": " - X",
        "link": "https://twitter.com/Eliyahugoldberg"
    },
    {
        "name": "Susanna Horowitz",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697383001/media/images/pyoeju4ggjhz8fotedkb.png",
        "link_name": "",
        "link": ""
    },
    {
        "name": "Yehuda Novak",
        "image": "https://res.cloudinary.com/hr1dzpvjo/image/upload/v1697402622/media/images/sbursaujbwtpchoh5aqw.jpg",
        "link_name": "",
        "link": ""
    }
]

function About() {
    const { t } = useTranslation();

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-200 p-4">
            <div className="bg-white p-8 rounded-md shadow-md max-w-2xl w-full">
                <h1 className="text-2xl font-bold mb-4">{t('about.title')}</h1>
                <p className="mb-4 text-xl">{t('about.description1')}</p>
                <p className="mb-4 text-xl">{t('about.description2')}</p>
                <p className="mb-2">{t('about.contact')}</p>
                <a href="mailto:info@civilpower.co.il" className="text-blue-500 underline">{t('info@civilpower.co.il')}</a>

                {/* Team Profiles Section */}
                <div className="mt-12">
                    <h2 className="text-xl font-bold mb-4">
                        {t('Among our Volunteers')}:
                    </h2>
                    <div className="flex flex-wrap gap-4 justify-center">
                        {data.map((item, index) => {
                            return (
                                <a href={item.link} target="_blank" rel="noreferrer" className="flex flex-col items-center p-3 rounded-3xl hover:bg-gray-500 hover:bg-opacity-10">
                                    <Avatar src={item.image} alt="Team member name" sx={{
                                        width: '7rem',
                                        height: '7rem',
                                    }} />
                                    <div>
                                        <p className="font-medium w-auto">{`${item.name}${item?.link_name}`}</p>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
