import { Button, IconButton, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useRedirectFunctions } from "@propelauth/react";

function LoginModal({ open, setOpen, title, description }) {
    const { redirectToSignupPage, redirectToLoginPage } = useRedirectFunctions();
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <>
                {open &&
                    <div className="bg-white max-h-[90vh] min-w-max absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl p-2" >
                        <div className="flex justify-end">
                            <IconButton
                                onClick={() => setOpen(false)}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        </div>
                        <div className="text-start px-4 pb-4">
                            <h1 className="text-2xl font-bold mb-2">{title}</h1>
                            <p className="text-gray-500 mb-4">{description}</p>
                            {/* <a
                                href={`${process.env.REACT_APP_AUTH_URL}/login`}
                                className="px-6 py-2 mt-3 text-center text-white bg-indigo-700 rounded-full lg:ms-5">
                                {t('Login')}
                            </a> */}
                            <div className="flex justify-center mt-3 gap-x-4">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => redirectToLoginPage({
                                        postLoginRedirectUrl: window.location.href
                                    })}
                                    sx={{ borderRadius: 99 }}
                                >
                                    {t('Login')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => redirectToSignupPage({
                                        postSignupRedirectUrl: window.location.href
                                    })}
                                    sx={{ borderRadius: 99 }}
                                >
                                    {t('Sign Up')}
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            </>
        </Modal>
    )
}

export default LoginModal