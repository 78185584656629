import { Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const headers = [
    ''
]

const TermsAndConditions = () => {
    const { t } = useTranslation();
    const terms = t('terms', { returnObjects: true });

    return (
        <Container className="py-12 flex flex-col text-start">
            <Typography variant="h4" className="pb-8 font-semibold text-center w-full">
                {terms.header}
            </Typography>

            <div className="max-w-xl w-full mx-auto">
                <ol className="list-decimal ps-5">
                    {Array.from({ length: 11 }).map((_, idx) => {
                        const headerKey = `header${idx + 1}`;
                        const textKey = `text${idx + 1}`;

                        return (
                            <li key={idx} className="mb-6">
                                <Typography variant="h6" className="font-medium">
                                    {terms[headerKey]}
                                </Typography>
                                <Typography className="mt-2">
                                    {terms[textKey]}
                                </Typography>
                            </li>
                        );
                    })}
                </ol>

                <Typography className="mt-12 text-gray-600">
                    {terms.header12}: {terms.text12} <a href="mailto:info@civilpower.co.il" className='text-blue-600'>info@civilpower.co.il</a>
                </Typography>
            </div>
        </Container>
    );
};

export default TermsAndConditions;